@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --corp-color-rgb: 255, 255, 255;
}

body {
  color: rgb(var(--foreground-rgb));
  background: rgb(var(--corp-color-rgb));
}

.fo-corp {
  color: rgb(var(--corp-color-rgb));
}

.fo-corp-bg {
  background: rgb(var(--corp-color-rgb));
}

.fo-anchor {
  position: relative;
  top: -120px;
  display: block;
  visibility: hidden;
}

@media (max-width: 1023px) {
  #menu {
    transition: max-height 0.5s ease;
    height: 0;
    max-height: 0;
    visibility: hidden;
  }

  #menu-toggle:checked ~ #menu {
    max-height: 800px;
    height: auto;
    visibility: visible;
  }

  #menu-toggle ~ label #menu-close {
    display: none;
  }

  #menu-toggle ~ label #menu-open {
    display: block;
  }

  #menu-toggle:checked ~ label #menu-close {
    display: block;
  }

  #menu-toggle:checked ~ label #menu-open {
    display: none;
  }

  .fo-anchor {
    top: -60px;
  }
}
